<script setup lang="ts">
interface Filter {
    key: string
    value: string
    filterValue: any
}

const mainStore = useMainStore();

const filterKeys = [
    {
        key: 'reviewFilter',
        value: (roomFilters: number[]) => {
            return roomFilters.map(num => {
                return {
                    key: 'reviewFilter',
                    value: `Vleresuar ${num}/5`,
                    filterValue: num
                }
            });
        }
    },
    {
        key: 'hotelStarsFilter',
        value: (roomFilters: number[]) => {
            return roomFilters.map(num => {
                return{
                    key: 'hotelStarsFilter',
                    value: `Hotel me ${num} yje`,
                    filterValue: num
                }
            });
        }
    },
    {
        key: 'price_range',
        value: (priceRange: number[]) => {
            const {min_price, max_price} = mainStore.minMaxPrice;
            const [currMin, currMax] = priceRange;
            if(currMin !== Number(min_price) || currMax !== Number(max_price)){
                return [{
                    key: 'price_range',
                    value: `€${currMin} - €${currMax}`,
                    filterValue: priceRange
                }]
            }
            return [];
        }
    }
    // 'reviewFilter',
    // 'hotelStarsFilter',
    // 'price_range',
]
const getSelectedFilters = computed(() => {
    let filters: Filter[] = []
    for(const filter of filterKeys){
        //@ts-ignore
        const value = mainStore[filter.key]
        filters = [...filters, ...filter.value(value)];
    }
    return filters
})

const handleClearFilter = (filter: Filter) => {
    if(filter.key === 'reviewFilter' || filter.key === 'hotelStarsFilter'){
        //@ts-ignore
        mainStore[filter.key] = mainStore[filter.key].filter(el => el !== filter.filterValue);
    } else {
        const {min_price, max_price} = mainStore.minMaxPrice;
        const resetPrice = [min_price, max_price];
        mainStore.price_range = JSON.parse(JSON.stringify(resetPrice));
        mainStore.old_price_range = JSON.parse(JSON.stringify(resetPrice));
    }
    mainStore.selectedPage = 1;
    mainStore.actGetPackages();
}
</script>

<template>
    <div class="flex pb-3 sm:pb-0 sm:justify-end sm:flex-wrap overflow-x-auto gap-3">
        <div v-for="filter in getSelectedFilters" class="flex min-w-max items-center gap-3 border border-primary rounded-full px-3 py-1 max-w-max text-primary bg-white font-semibold">
            <slot>
                {{filter.value}}
            </slot>
            <font-awesome-icon @click="handleClearFilter(filter)" :icon="['far', 'circle-xmark']" class="text-[16px] cursor-pointer"/>
        </div>
    </div>
</template>

<style scoped>

</style>
