<script setup lang="ts">
import RoomBasisFilter from "~/components/filters/RoomBasisFilter.vue";
const {roomBasisFilterInfo} = useUtils()
const show = ref(false);
const currRoom = ref('')
const mainStore = useMainStore();

const dropdownLabel = computed(() => {
    if(!mainStore.roomBasisFilter) {
         return 'Plani ushqimor';
    }
    return roomBasisFilterInfo(mainStore.roomBasisFilter)
})

const disabledClass = computed(() => {
    if(mainStore.disableFilters){
        return 'bg-gray-300 pointer-events-none border-gray-300 text-gray-500';
    }
    return 'bg-white border-secondary border cursor-pointer';
})

const handleOptionSelect = (room: string) => {
    // currRoom.value = room
    show.value = false;
}
</script>

<template>
    <div :class="['text-secondary']" v-click-outside="() => {show = false}">
        <div :class="['select-none rounded-[10px]', disabledClass]" @click="() => {show = !show}">
            <div :class="['block overflow-hidden relative w-full']">
                <div class="flex gap-10 py-4 px-5 items-center font-bold text-base justify-between min-w-max">
                    {{ dropdownLabel }}
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-show="show" class="z-[30] absolute bg-white max-w-min top-16 w-full shadow-[0_0_5px_0] shadow-gray-normal rounded-[10px] flex flex-col text-center overflow-auto">
                <RoomBasisFilter @option-changed="handleOptionSelect"/>
            </div>
        </transition>
    </div>
</template>

<style scoped>

</style>
