<script setup lang="ts">
import PriceFilter from "~/components/filters/PriceFilter.vue";
import TripAdvisorFilter from "~/components/filters/TripAdvisorFilter.vue";
import StarsFilter from "~/components/filters/StarsFilter.vue";
import ButtonDefault from "~/components/ButtonDefault.vue";
import DisplayFilters from "~/components/filters/DisplayFilters.vue";
const breakpoints = useBreakpoints({
    sidebar: 500
});

const largerThanSidebar = breakpoints.greater('sidebar');

const emit = defineEmits(['closed'])
const mainStore = useMainStore();

const handleSearch = () => {
    mainStore.actGetPackages();
    emit('closed');
}

const disableApiCall = computed(() => {
    return !largerThanSidebar.value;
})

const handleClose = () => {
    emit('closed');
    const [old_min, old_max] = mainStore.old_price_range;
    const [curr_min, curr_max] = mainStore.price_range;
    if(old_min !== curr_min || old_max !== curr_max){
        mainStore.price_range = [old_min, old_max]
    }
}

</script>

<template>
    <div class="sidebar-wrapper shadow-lg">
        <div class="text-2xl font-bold flex justify-between items-center">
            Filtro rezultatet
            <font-awesome-icon :icon="['far', 'circle-xmark']" class="text-primary cursor-pointer" @click="handleClose"/>
        </div>

        <PriceFilter :no-api-call="disableApiCall"/>
        <StarsFilter  :no-api-call="disableApiCall"/>
        <TripAdvisorFilter  :no-api-call="disableApiCall"/>
        <div class="sticky pb-6 py-4 bg-gray-lighter bottom-0 sm:hidden">
            <button-default @click="handleSearch" class="py-3 w-full">Kërko</button-default>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.sidebar-wrapper{
    @apply overflow-y-auto fixed top-0 left-0 h-[100dvh] z-[21] max-w-md w-full px-6 pt-6 bg-gray-lighter flex flex-col gap-10
}

</style>
