<script setup lang="ts">
import TripAdvisorCircle from "~/components/uiComponents/TripAdvisorCircle.vue";
import TripAdvisorLabel from "~/components/filters/TripAdvisorLabel.vue";
const props = defineProps<{noApiCall?: boolean}>()

const mainStore = useMainStore()
const handleFilter = () => {
    mainStore.selectedPage = 1;
    if(!props.noApiCall){
        mainStore.actGetPackages();
    }
}



</script>

<template>
    <div>
        <div class="flex justify-between items-center mb-3">
            <h3>Trip Advisor Rating</h3>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-secondary" />
        </div>
        <div class="flex flex-col gap-4 bg-white p-6 rounded-[10px]">
            <TripAdvisorLabel v-for="num in 5" :key="num + 'trip'" :num="num" @changed="handleFilter"/>
<!--            <label v-for="num in 5"-->
<!--                   :for="num + 'trip'"-->
<!--                   :key="num + 'trip'"-->
<!--                   class="flex cursor-pointer justify-between items-center">-->
<!--                <TripAdvisorCircle :number-score="5 - (num - 1)"/>-->

<!--                <input :id="num + 'trip'" type="checkbox"-->
<!--                       v-model="mainStore.reviewFilter"-->
<!--                       @change="handleFilter"-->
<!--                       :value="(5 - (num - 1))"/>-->
<!--            </label>-->
        </div>
    </div>
</template>

<style scoped>

</style>
