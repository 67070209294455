<script setup lang="ts">
import VueSlider from "vue-3-slider-component";
import InputSkeleton from "~/components/InputSkeleton.vue";
const mainStore = useMainStore()
const props = defineProps<{noApiCall?: boolean}>();
const currPrice = ref([0,0]);

const {price_range} = storeToRefs(mainStore)

const getPackages = () => {
    mainStore.price_range = JSON.parse(JSON.stringify(currPrice.value));
    if(!props.noApiCall){
        console.log('gettting packages')
        mainStore.selectedPage = 1;
        mainStore.actGetPackages();
    }
}

const minMaxPrice = computed(() => {
    return mainStore.filterData?.minMaxPrices || {min_price: 0, max_price: 0}
})

watch(price_range, (value) => {
    if(value) currPrice.value = JSON.parse(JSON.stringify(value))
})

</script>

<template>
    <div>
        <div class="flex justify-between items-center mb-3">
            <h3>Buxheti i udhëtimit</h3>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-secondary" />
        </div>
        <div class="bg-white p-3 pt-5 rounded-[10px] flex flex-col gap-5">
<!--            <Toggle v-model="priceMode" :options="['Për person', 'Totali']" id="priceMode" class="mb-5">-->
<!--                <template #default="{ option }">-->
<!--                    <div class="px-2 py-2">-->
<!--                        {{ option }}-->
<!--                    </div>-->
<!--                </template>-->
<!--            </Toggle>-->
            <client-only>
                <VueSlider v-model="currPrice"
                           v-if="minMaxPrice.min_price && minMaxPrice.min_price < minMaxPrice.max_price"
                           :min="minMaxPrice.min_price"
                           :max="minMaxPrice.max_price"
                           @dragEnd="getPackages"
                           :interval="1"
                />
            </client-only>
            <div class="flex justify-between w-full gap-5 items-center">
                <input-skeleton class="h-[unset] py-3 px-3 bg-gray-100 justify-start text-start">
                    <div class="flex flex-col">
                        <div class="text-sm font-light text-gray-400">Min</div>
                        {{ minMaxPrice.min_price }}€
                    </div>
                </input-skeleton>
                <div>
                    -
                </div>
                <input-skeleton class="h-[unset]  py-3 px-3  bg-gray-100 text-start">
                    <div class="flex flex-col">
                        <div class="text-sm font-light text-gray-400">Max</div>
                        {{ minMaxPrice.max_price }}€
                    </div>
                </input-skeleton>
            </div>
        </div>
    </div>
</template>

<style>
/*
#16163F
#ED1B23
*/
.vue-slider-dot-tooltip-inner {
    border-color: #16163F !important;
    background-color: #16163F !important;
}

.vue-slider-process {
    background-color: #16163F !important;

}
</style>
