<script setup lang="ts">
import SingleBasis from "~/components/filters/SingleBasis.vue";

const emit = defineEmits(['optionChanged']);
const mainStore = useMainStore();
const route = useRoute();
const router = useRouter();

const roomTypes = [
    '',
    'RO',
    'BB',
    'HB',
    'FB',
    'AI',
]


const disabledClass = computed(() => {
    if(mainStore.disableFilters){
        return 'bg-gray-300 pointer-events-none border-gray-300 text-gray-500';
    }
    return '';
})

const handleBasisChange = (room: string) => {
    emit('optionChanged', room);
    mainStore.roomBasisFilter = room;
    mainStore.selectedPage = 1;
    mainStore.actGetPackages();
    // history.replaceState({}, '', )
    router.replace({query: {...route.query, room_basis: room}})
}
</script>

<template>
<!--        <div class="flex justify-between items-center mb-3">-->
<!--            <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-secondary" />-->
<!--        </div>-->
        <div :class="['bg-white pt-1 px-2 rounded-[10px] max-w-max', disabledClass]">
<!--                <h3 class="mb-3">Plani ushqimor</h3>-->

            <div class="flex gap-5 pb-3 sm:pb-1 overflow-x-auto">
                <SingleBasis
                    v-for="room in roomTypes"
                    :key="room"
                    @room-selected="handleBasisChange"
                    :disabled="!!disabledClass"
                    :room="room"
                />
<!--                <div v-for="room in roomTypes" class="flex flex-col justify-center items-center gap-3">-->
<!--                    <div-->
<!--                        @click="handleBasisChange(room)"-->
<!--                        :class="[-->
<!--                            'rounded-full border relative h-14 w-14 cursor-pointer border-gray-light',-->
<!--                            {'border-primary/40 bg-primary/40': mainStore.roomBasisFilter === room && !disabledClass}-->
<!--                        ]"-->
<!--                    >-->
<!--                        <nuxt-icon :name="`room-basis/${getIcon(room)}`"-->
<!--                                   :class="['text-xl h-min absolute-center', {'text-primary': mainStore.roomBasisFilter === room && !disabledClass}]" filled/>-->
<!--                    </div>-->
<!--                    <div class="min-w-max">-->
<!--                        {{roomBasisFilterInfo(room)}}-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
</template>

<style scoped>

</style>
