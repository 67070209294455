import * as yup from 'yup';

const today = new Date();
today.setHours(0,0,0,0)

const querySchema = yup.object().shape({
    nights: yup.number().positive().integer().required(),
    origin_id: yup.number().positive().integer().required(),
    destination_id: yup.number().positive().integer().required(),
    page: yup.number().positive().integer().required(),
    adults: yup.number().positive().integer().required(),
    children: yup.number().integer().min(0).required(),
    infants: yup.number().integer().min(0).required(),
    refresh: yup.number().integer().min(0),
    batch_id: yup.string().uuid().nullable(),
    room_basis: yup.string().nullable(),
    sort_by: yup.string().matches(/(total_price|stars|review_score)/),
    sort_order: yup.string().matches(/(ASC|DESC)/).when("sort_by", {
        is: yup.string(),
        then: yup.string().required()
    }),
    checkin_date: yup.date().min(today, "Data e kerkimit eshte ne te shkuaren!").required("Data eshte inkorrekte"),
})

export const useValidateSearchQuery = () => {
    const {query} = useRoute();
    const mainStore = useMainStore();
    const queryParams = ref();
    const errors = ref([])

    const errorMessage = computed(() => {
        let msg = '';
        errors.value.forEach(e => {
            msg += `${e.message} <br>`
        })
        return msg;
    })

    const yupValidate = async () => {
        const {selectedOrigin, selectedDestination} = mainStore;
        // const origin_id = selectedOrigin?.id
        // const destination_id = selectedDestination?.id

        try {
            const finalQuery = {
                ...query,
                // origin_id,
                // destination_id
            }
            // console.log('finalQuery', finalQuery)
            const res = await querySchema.validate(finalQuery, {abortEarly: false});
            queryParams.value = querySchema.cast(finalQuery)
            // console.log('res', queryParams.value)
        } catch (err) {
            errors.value = err.inner
            // err.inner.forEach(e => {
            //     console.log(e.message, e.path)
            // })
        }
    }

    return {
        queryParams,
        errorMessage,
        yupValidate,
    }
}
