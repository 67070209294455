<script setup>
const props = defineProps(['num']);
const mainStore = useMainStore();

const stars = 5 - (props.num - 1);


const countByStars = computed(() => {
    return mainStore.filterData?.packagesCountByStars
})

const pckgCount = computed(() => {
    if(countByStars.value){
        return countByStars.value[stars]?.package_count || 0
    }
    return 0
})


</script>

<template>
    <label :for="stars + 'star'" :class="['flex', (!pckgCount ? '!cursor-default' : 'cursor-pointer')]">
        <span class="flex gap-1">
            <nuxt-icon name="star-solid" v-for="star in stars"
                       :key="stars + '-onestar-' + star"
                       :class="['text-2xl', (pckgCount ? 'text-yellow-500' : 'text-gray-light')]"/>
        </span>
        <span :class="['ml-auto font-semibold leading-none mr-2', {'text-gray-light': !pckgCount}]">
            ({{pckgCount}})
        </span>
        <input :id="stars + 'star'"
               type="checkbox"
               v-model="mainStore.hotelStarsFilter"
               @change="$emit('changed')"
               :value="stars"
               :disabled="!pckgCount"/>
    </label>
</template>

<style scoped>

</style>
