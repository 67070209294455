<script setup>
import HotelStarsLabel from "~/components/filters/HotelStarsLabel.vue";

const props = defineProps(['noApiCall'])
const mainStore = useMainStore()

const handleFilter = () => {
    mainStore.selectedPage = 1;
    if(!props.noApiCall){
        mainStore.actGetPackages();
    }
}

const countByStars = computed(() => {
    return mainStore.filterData?.packagesCountByStars
})

</script>

<template>
    <div>
        <div class="flex justify-between items-center mb-3">
            <h3>Hotel Stars</h3>
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="text-secondary" />
        </div>
    <div class="flex flex-col gap-4 bg-white p-6 rounded-[10px]">
        <HotelStarsLabel v-for="num in 5" :key="num + 'StarsParent'" :num="num" @changed="handleFilter"/>
<!--        <label v-for="num in 5" :key="num + 'parent'" :for="num + 'star'" class="flex cursor-pointer relative">-->
<!--            <span class="flex gap-1">-->
<!--                <nuxt-icon name="star-solid" v-for="star in (5 - (Number(num) - 1))"-->
<!--                           :class="['text-2xl', (countByStars?.[num]?.package_count ? 'text-yellow-500' : 'text-gray-light')]"/>-->
<!--            </span>-->
<!--            <span class="ml-auto font-semibold leading-none mr-2">-->
<!--                ({{countByStars?.[num]?.package_count || 0}})-->
<!--            </span>-->
<!--            <input :id="num + 'star'"-->
<!--                   type="checkbox"-->
<!--                   v-model="mainStore.hotelStarsFilter"-->
<!--                   @change="handleFilter"-->
<!--                   :value="(5 - (num - 1))"-->
<!--                   :disabled="!countByStars?.[num]?.package_count"/>-->
<!--        </label>-->
    </div>
    </div>
</template>

<style scoped>

</style>
