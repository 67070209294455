<script setup>
import TripAdvisorCircle from "~/components/uiComponents/TripAdvisorCircle.vue";
const props = defineProps(['num']);

const mainStore = useMainStore();

const reviewScore = 5 - (props.num - 1)

const countByReviews = computed(() => {
    return mainStore.filterData?.packagesCountByReviewScores
})

const pckgCount = computed(() => {
    if(countByReviews.value){
        const naturalNum = countByReviews.value[`${reviewScore}.00`]?.package_count || 0;
        const floatNum = countByReviews.value[`${reviewScore}.50`]?.package_count || 0;
        return naturalNum + floatNum
    }
    return 0
})

</script>

<template>
    <label :for="num + 'trip'"
           class="flex cursor-pointer items-center">
        <TripAdvisorCircle :number-score="reviewScore"/>

        <span :class="['ml-auto font-semibold leading-none mr-2', {'text-gray-light': !pckgCount}]">
            ({{pckgCount}})
        </span>
        <input :id="num + 'trip'" type="checkbox"
               :disabled="!pckgCount"
               v-model="mainStore.reviewFilter"
               @change="$emit('changed')"
               :value="(5 - (num - 1))"/>
    </label>
</template>

<style scoped>

</style>
