<script setup>
const props = defineProps(['room', 'disabled']);
const emit = defineEmits(['roomSelected']);
const mainStore = useMainStore()
const {roomBasisFilterInfo} = useUtils();

const handleBasisChange = (room) => {
    if(!pckgCount.value) return;
    emit('roomSelected', room);
}


const countByBasis = computed(() => {
    return mainStore.filterData?.packagesCountByRoomBasis
})

const pckgCount = computed(() => {
    if(!props.room){
        return mainStore.searchTotal;
    }
    if(countByBasis.value){
        return countByBasis.value[props.room]?.package_count || 0
    }
    return 0;
})

const getIcon = (room) => {
    switch (room) {
        case 'BB':
        case 'CB':
            return 'coffe-mug';
        case 'AI':
            return 'all-inclusive-3';
        case 'FB':
            return 'full-board';
        case 'HB':
            return 'half-board';
        case 'RO':
            return 'utensils-slash';
        default:
            return `utensils`;
    }
}


</script>

<template>
    <div class="flex flex-col justify-center items-center gap-3 min-w-max">
        <div
            @click="handleBasisChange(room)"
            :class="[
                'rounded-full border relative h-14 w-14 border-gray-light',
                {'border-primary/40 bg-primary/40': mainStore.roomBasisFilter === room && !disabled},
                {'cursor-pointer': !!pckgCount},
            ]"
        >
            <nuxt-icon
                :name="`room-basis/${getIcon(room)}`"
                :class="[
                   'text-xl h-min absolute-center',
                   {'!text-gray-normal': !pckgCount},
                   {'text-primary': mainStore.roomBasisFilter === room && !disabled}
                ]"
                filled
            />
        </div>
        <div
            :class="[
                'min-w-max',
               {'!text-gray-normal': !pckgCount}
           ]"
        >
            {{roomBasisFilterInfo(room)}} ({{pckgCount}})
        </div>
    </div>
</template>

<style scoped>

</style>
