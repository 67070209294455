<template>
    <div ref="resultsSection" id="resultsSection" :class="['flex min-h-[100dvh] flex-col items-center w-full px-3.5 sm:px-5 lg:px-10', {'pointer-events-none': mainStore.loadingPackages}]">
<!--            <SearchFilters class="max-w-page mt-11"/>-->
<!--        <transition name="slide-fade">-->
            <sidebar-filters v-show="showSidebarFilters" @closed="handleSidebarClose"/>
<!--        </transition>-->
        <div class="max-w-screen-2xl w-full mt-8">
<!--                <TellUs/>-->
<!--                <div class="col-span-3">-->
<!--                    <FiltersIndex/>-->
<!--                </div>-->
<!--                <button @click="closeSocket">CLOSE SOCKET</button>-->
                <div class="flex flex-col gap-4 md:gap-10 col-span-12">
<!--                    {{errorMessage}}-->
                    <div class="flex flex-col relative sm:flex-row gap-3 lg:gap-10">
                        <DropdownRoomBasis class="hidden sm:block"/>
                        <div class="flex sm:items-center justify-between gap-3 flex-col sm:flex-row ml-auto">
<!--                            <SortOptions/>-->
                            <div class="flex gap-3 text-secondary">
                                <SearchHotel class="max-w-md w-full"/>
                                <div @click="showSidebarFilters = !showSidebarFilters" :class="['base-input max-w-max h-auto py-4 cursor-pointer flex items-center gap-3', {
                                    'bg-gray-300 pointer-events-none !text-gray-500': mainStore.disableFilters
                                }]">
                                    Filtro
                                    <font-awesome-icon :icon="['fas', 'sliders']" class="text-2xl"/>
                                </div>
                            </div>
                        </div>
                        <RoomBasisFilter class="block sm:hidden"/>
                        <!--                            <dropdown-room-basis class="flex-shrink"/>-->
                    </div>
                    <DisplayFilters/>
                    <div>
                        <transition name="fade" mode="out-in">
                            <div v-if="mainStore.loadingPackages" class="flex flex-col items-center justify-center gap-y-10">
                                <PlaneLoader/>
                                <!--                                <PackageCardLoading v-for="idx in 3" :key="idx + 'load'"/>-->
                            </div>
                            <div v-else-if="mainStore.travelPackages?.length" class="flex flex-col gap-y-10">

                                <PackageCard v-for="travelPackage in mainStore.travelPackages"
                                             :package="travelPackage"
                                             :key="travelPackage.id"
                                             class="relative"/>
                                <div class="flex w-full justify-center">
                                    <Pagination
                                        :per-page="10"
                                        :count="mainStore.searchTotal"
                                        :curr-page="mainStore.selectedPage"
                                        @page-changed="handlePageChanged"/>
                                </div>
                            </div>
                            <div v-else class="mt-10 flex flex-col gap-y-10 justify-center items-center w-full">
                                <div class="text-center text-secondary font-semibold">
                                    <div v-if="errorMessage.length" class="flex flex-col gap-5 text-3xl text-primary text-left">
                                        <div class="mt-5 text-secondary text-center">
                                            <div>Parametrat e kerkimit nuk jane te sakte. <br> Ju lutem ndryshoni datat ose parametrat e kerkimit.</div>
                                            <div class="text-lg mt-10 text-center" v-html="errorMessage"></div>
                                        </div>
                                    </div>
                                    <div v-else class="text-lg lg:text-2xl leading-loose lg:leading-[1.5]">
                                        {{ metaTitle }}
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
</template>
<script setup>
import RoomBasisFilter from "~/components/filters/RoomBasisFilter.vue";
import DropdownRoomBasis from "~/components/filters/DropdownRoomBasis.vue";
import DisplayFilters from "~/components/filters/DisplayFilters.vue";
import SearchHotel from "~/components/filters/SearchHotel.vue";
import SidebarFilters from "~/components/filters/SidebarFilters.vue";
import PlaneLoader from "~/components/PlaneLoader.vue";
import {useValidateSearchQuery} from "~/composables/vaildateSearchQuery.js";
const PackageCard = defineAsyncComponent(() => import('/components/cards/PackageCard.vue'))
const Pagination = defineAsyncComponent(() => import('/components/Pagination.vue'))


const runtimeConfig = useRuntimeConfig()
const mainStore = useMainStore()
const resultsSection = ref(null);
const liveSearchWS = ref();
const {yupValidate, queryParams, errorMessage} = useValidateSearchQuery();
const route = useRoute();
const router = useRouter();
const showSidebarFilters = ref(false)
const {params} = route;

const scrollToResults = () => {
    // resultsSection.value?.scrollIntoView({
    //     behavior: 'smooth'
    // })
}

const handleScrollTop = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, 10)
}

const isQueryValid = computed(() => {
    return !errorMessage.value.length
})



const handleSidebarClose = () => {
    showSidebarFilters.value = false;
    scrollToResults();
}

const handlePageChanged = (page) => {
    console.log(page, 'pageee')
    mainStore.selectedPage = page;
    mainStore.actGetPackages();
    resultsSection.value?.scrollIntoView({
        behavior: 'smooth'
    });
    router.replace({path: route.path, query: {...mainStore.getSearchParams, page}})
}


const getSetOriginsDestinations = async () => {
    const {origin, destination} = params;
    if(origin && destination){
        console.log({origin, destination})
        if(!mainStore.allOrigins.length){
            await mainStore.actGetOrigins();
        }
        const originFound = mainStore.allOrigins.find(o => {
            return o.name.toLowerCase().replaceAll(' ', '-') === origin
        });
        // console.log({originFound})
        if(originFound){
            mainStore.actSetOrigin(originFound);
            // console.log('length::', originFound.destinations.length)
            mainStore.actSetAllDestinations(originFound.destinations)
            // await mainStore.actGetDestinations(originFound.id);
            const destinationFound = mainStore.allDestinations.find(o => o.name.toLowerCase().replaceAll(' ', '-') === destination);
            if(destinationFound) {
                mainStore.actSetDestination(destinationFound)
                mainStore.originSearch = mainStore.selectedOrigin.name;
                mainStore.destinationSearch = mainStore.selectedDestination.name;
            }
        }
    }
}

const setSearchValues = async (noApiCall) => {
    // handleQueryValidate();
    await yupValidate(mainStore);

    if(errorMessage.value.length > 0) {
        mainStore.loadingPackages = false;
    } else {
        const validParams = queryParams.value;
        // console.log('checking origin and destination', validParams)
        if(!mainStore.selectedOrigin || !mainStore.selectedDestination) {
            await getSetOriginsDestinations();
        }

        console.log(validParams.room_basis, 'validParams.room_basis')

        if(validParams.room_basis){
            mainStore.roomBasisFilter = validParams.room_basis;
        }

        mainStore.originSearch = mainStore.selectedOrigin.name;
        mainStore.destinationSearch = mainStore.selectedDestination.name;

        mainStore.selectedDate = validParams.checkin_date;
        mainStore.selectedNights = validParams.nights;
        mainStore.selectedPage = validParams.page;
        mainStore.travelersNumber = {
            adults: validParams.adults,
            children: validParams.children,
            infants: validParams.infants,
        }
        // console.log(validParams.batch_id, 'validParams.batch_id')
        mainStore.batchId = validParams.batch_id

        mainStore.actSetCurrSearchParams();

        if(!noApiCall){
            if(!process.server && !mainStore.batchId){
                await mainStore.actLiveSearch();
            } else {
                console.log('calling get packages')
                await mainStore.actGetPackages();
            }
        } else if(mainStore.batchId){
            await mainStore.actGetPackages();
        }
    }

    await nextTick(() => {
        scrollToResults();
    })
}



const closeSocket = () => {
    console.log('closing socketi')
    if(liveSearchWS.value){
        liveSearchWS.value.onclose = function () {}; // disable onclose handler first
        liveSearchWS.value.close();
        liveSearchWS.value = null;
    }
}

const createWebsocket = () => {
    liveSearchWS.value = new WebSocket(runtimeConfig.public.wssUrl);
    const searchWS = liveSearchWS.value
    searchWS.onopen = () => {
        searchWS.send(JSON.stringify({
            "event": "pusher:subscribe",
            "data": {
                "channel": "search"
            }
        }));
    };

    searchWS.onclose = () => {
        liveSearchWS.value = null;
        createWebsocket();
    }

    searchWS.onmessage = async ({data}) => {
        const batchId = mainStore.batchId;
        const parseData = JSON.parse(data)
        // console.log('parseData:::', JSON.parse(parseData?.data || '{}'))
        if(parseData.event === "App\\Events\\LiveSearchFailed"){
            const messageData = JSON.parse(parseData.data)
            if(messageData.batchId === batchId){
                mainStore.setPackages(null)
            }
        }

        if(parseData.event === 'pusher_internal:subscription_succeeded'){
            console.info('Websocket connection succeeded')
        }
        console.log(parseData.event)
        if(parseData.event === "App\\Events\\LiveSearchCompleted"){
            console.log('live search completed', batchId)
            if(batchId){
                const {packages, batch_id, max, min} = JSON.parse(parseData?.data || '{}')
                console.log('websocket packages:::', {packages: packages?.data, batch_id});
                if(batch_id === batchId){
                    let maxCeil = Math.ceil(max);
                    let minCeil = Math.floor(min);
                    mainStore.setPackages(packages.data);
                    mainStore.actSetPaginatedFilterData();
                    await router.replace({path: route.path, query: {...route.query, batch_id}, force: true})
                    mainStore.searchTotal = packages.total;
                    // mainStore.price_range = [minCeil, maxCeil];
                    // mainStore.maxMinPrice = [minCeil, maxCeil];
                    setTimeout(() => {
                        scrollToResults();
                    })
                }
            }
        }
    };
}

onBeforeUnmount(() => {
    //set to true to prevent double mount for the children component
    //when loading value changes when navigating back to search page
    console.log('unmounting')
    mainStore.loadingPackages = true;
    closeSocket();
    window.onpopstate = function () {};
})

onMounted(() => {
    setSearchValues();
    // window.onpopstate = (e) => {
    //     e.preventDefault();
    //     if(!mainStore.imageCollectionIsOpen){
    //         router.push('/')
    //     } else {
    //         console.log('closing collection')
    //         history.pushState({}, "", e.state.forward)
    //         mainStore.imageCollectionIsOpen = false;
    //         setTimeout(() => {
    //             if(mainStore.packageToScrollTo){
    //                 const element = document.getElementById(`packageNo-${mainStore.packageToScrollTo}`);
    //                 if(element){
    //                     const y = element.getBoundingClientRect().top - 20;
    //                     window.scrollTo({top: y});
    //                 }
    //                 mainStore.packageToScrollTo = null;
    //             }
    //         }, 150)
    //     }
    // }
})

onBeforeRouteLeave((to, from) => {
    //this handles the case when user
    // is trying to go back with the intention to close the image collection modal
    console.log('route leave', to, from)
    if(mainStore.imageCollectionIsOpen){
        mainStore.imageCollectionIsOpen = false;
        return false;
    } else {
        mainStore.actResetParams();
        mainStore.actResetFilters();
    }
})

definePageMeta({
    layout: 'searchlayout',
    // layoutTransition: {
    //     name: 'page',
    //     mode: 'out-in',
    //     onAfterEnter: () => {
    //         const el = document.getElementById('resultsSection');
    //         el?.scrollIntoView({behavior: 'smooth'});
    //     }
    // },
    // pageTransition: {
    //     name: 'page',
    //     mode: 'out-in',
    //     onAfterEnter: () => {
    //         const el = document.getElementById('resultsSection');
    //         el?.scrollIntoView({behavior: 'smooth'});
    //     }
    // }
})


if(process.server){
    await getSetOriginsDestinations();
    setSearchValues(true);
} else {
    if(!liveSearchWS.value){
        console.log('creating socketi')
        createWebsocket();
    }
    // setSearchValues();
}


const currYear = new Date().getFullYear();

const metaDescription = () => {
    return !mainStore.selectedOrigin?.city || !mainStore.selectedDestination?.city
        ? 'Kërko paketa turistike'
        : `Paketa Turistike nga ${mainStore.selectedOrigin.city} ne ${mainStore.selectedDestination.city} ${currYear}`;
}

const metaTitle = computed(() => {
    if(process.server && mainStore.selectedOrigin?.city && mainStore.selectedDestination?.city)
        return `Paketa Turistike nga ${mainStore.selectedOrigin.city} ne ${mainStore.selectedDestination.city} ${currYear}`;
    if(mainStore.loadingPackages)
        return 'Duke kërkuar ...';
    if(!mainStore.selectedOrigin?.city || !mainStore.selectedDestination?.city)
        return 'Kërko paketa turistike';
    if(!mainStore.travelPackages?.length){
        if(mainStore.packagesAreFromLiveSearch)
            return 'Serveri eshte i ngarkuar ju lutem beni kerkimin serisht dhe nese perseritet ndryshoni datat ose parametrat e kerkimit.';
        return 'Nuk ka oferta. Ju lutem ndryshoni parametrat e kerkimit!';
    }

    return `Paketa Turistike nga ${mainStore.selectedOrigin.city} ne ${mainStore.selectedDestination.city} ${currYear}`;
})

const getOGImage = () => {
    return `${runtimeConfig.public.storageUrl}/${mainStore.selectedDestination?.destination_photos?.[0]?.file_path}`;
}


useSeoMeta({
    title: () => metaTitle.value,
    ogTitle: () => metaTitle.value,
    description: () => metaDescription(),
    ogDescription:  () => metaDescription(),
    ogType: 'website',
    ogLocale: 'sq_AL',
    ogSiteName: 'Paketa Turistike',
    ogImage: () => getOGImage(),
    ogImageWidth: '1200',
    ogImageHeight: '630',
    ogImageType: 'image/jpeg',
    twitterCard: "summary_large_image",
    twitterTitle: 'Paketa Turistike',
    twitterDescription: () => metaDescription(),
    twitterSite: '@HimaTravel',
    twitterImage:  () => getOGImage(),
})
</script>

<style scoped>

</style>
