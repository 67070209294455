<script setup lang="ts">
const mainStore = useMainStore();



</script>

<template>
    <div class="relative text-secondary">
        <input :class="['base-input h-auto py-4 text-left', {'!bg-gray-300 !placeholder-gray-500 pointer-events-none': mainStore.disableFilters || true}]" type="text" placeholder="Emër hoteli">
        <font-awesome-icon :icon="['fas', 'magnifying-glass']" :class="['top-1/2 -translate-y-1/2 right-3 sm:right-5 absolute', {'text-gray-500': mainStore.disableFilters  || true}]"/>
    </div>
</template>

<style scoped>

</style>
